<template>
  <div class="card mb-3 shadow-sm sticky-top">
    <div class="card-body">
      <!-- Tab Navigation -->
      <ul
        class="nav nav-pills align-items-center"
        id="pills-tab"
        role="tablist"
      >
        <!-- General Information -->
        <li class="nav-item" role="presentation">
          <a
            href="#general-info"
            class="nav-link active"
            id="general-info-tab"
            data-toggle="pill"
            role="tab"
            aria-controls="general-info"
            aria-selected="true"
          >
            <i class="far fa-list-alt" v-if="errors && !errors.length"></i>
            <i class="fas fa-exclamation-triangle text-warning" v-else></i>
            <span class="ml-1">Genel Bilgiler</span>
          </a>
        </li>
        <!-- ./General Information -->

        <!-- Package Details -->
        <li class="nav-item ml-2" role="presentation">
          <a
            href="#packet-detail"
            class="nav-link"
            id="packet-detail-tab"
            data-toggle="pill"
            role="tab"
            aria-controls="packet-detail"
            aria-selected="false"
          >
            <i class="fas fa-bars"></i>
            <span class="ml-1">Paket Detayları</span>
          </a>
        </li>
        <!-- ./Package Details -->

        <!-- Marketplace Details -->
        <li class="nav-item ml-2" role="presentation">
          <a
            href="#marketplace-detail"
            class="nav-link"
            id="marketplace-detail-tab"
            data-toggle="pill"
            role="tab"
            aria-controls="marketplace-detail"
            aria-selected="false"
          >
            <i class="fas fa-cart-plus"></i>
            <span class="ml-1">Pazaryeri</span>
          </a>
        </li>
        <!-- ./Marketplace Details -->

        <!-- Submit Button -->
        <li class="nav-item ml-auto">
          <WeSubmitButton
            parent-class="text-center"
            v-bind:submit-status="submit"
            v-bind:update="$route.params.id"
            v-on:submit="saveForm"
          />
        </li>
        <!-- ./Submit Button -->
      </ul>
      <!-- Tab Navigation -->
    </div>
  </div>
</template>

<script>
export default {
  name: "Navigation",
  props: {
    submit: {
      default: false,
    },
    errors: {
      default: "",
    },
  },
  methods: {
    saveForm() {
      this.$emit("save-form");
    },
  },
};
</script>
